
import React, { Suspense, useState, useRef, useMemo} from 'react'
// A THREE.js React renderer, see: https://github.com/drcmda/react-three-fiber
import { Canvas, useFrame} from 'react-three-fiber'
// A React animation lib, see: https://github.com/react-spring/react-spring
import * as THREE from 'three'
import { useSpring, animated } from 'react-spring/three'
import './styles.css'
import { OrbitControls, Loader, useGLTF, useAnimations, Preload } from '@react-three/drei'
import { useDarkMode } from '../hooks'
import { BlockMath } from 'react-katex';
import 'katex/dist/katex.min'



function Octa() {
 
  const [colorMode] = useDarkMode()
  const Mat = colorMode == `dark` ?  <meshNormalMaterial attach="material" wireframe='true'/> : <meshLambertMaterial attach="material" color="red"  />   ;
  const Tor = colorMode == `dark` ?  <torusKnotGeometry attach="geometry" wireframe='true' args={[1.2, 0.25, 300,3+Math.trunc(Math.random()*10) ,7+Math.trunc(Math.random()*100)]}/> : <torusKnotGeometry attach="geometry" args={[1.2, 0.25, 300,3+Math.trunc(Math.random()*10) ,7+Math.trunc(Math.random()*100)]}/>   ;
  const [active, setActive] = useState(false)
  

  const { color, pos, ...props } = useSpring({
  
    
    scale: active ? [1, 1, 1] : [1, 1, 1],

  

    config: { mass: 100, tension: 1000, friction: 300, precision: 0.00001 }
  })

  
  return (
    <group   onPointerDown={() => setActive(!active) } >
     
      <animated.mesh >
     
        {Tor}
    {Mat}

      </animated.mesh>
     
    </group>
    
  )

  
}

function Model(props) {
  const { nodes, materials, animations } = useGLTF('/billie.glb')
  const [colorMode] = useDarkMode()
  const mate = colorMode == `dark` ? materials.billie : new THREE.MeshNormalMaterial( {color:'yellow' } )   ;
  const group = useRef()
  const { actions } = useAnimations(animations, group)
  return (
    <group ref={group} {...props} dispose={null}>
      <group rotation={[-Math.PI / 2, 0, 0]}>
        <group scale={[0.26, 0.26, 0.26]}>
          <group rotation={[Math.PI / 2, 0, 0]}>
            <group position={[0, 0.6, 0]}>
              <mesh material={mate} geometry={nodes.mesh_0.geometry}  />
            </group>
            <group position={[0, 0, 0]}>
              <primitive object={nodes.GLTF_created_0_rootJoint} />
              <skinnedMesh
                material={mate}
                geometry={nodes.mesh_1.geometry}
                skeleton={nodes.mesh_1.skeleton}
              />
            </group>
          </group>
        </group>
      </group>
    </group>
  )
}



function Poliedros() {
  const [colorMode] = useDarkMode()
  const mat = colorMode == `dark` ? new THREE.MeshNormalMaterial(  ) : new THREE.MeshPhongMaterial( {color:'black'} )   ;
 let group = useRef()
 let theta = 0
 useFrame(() => {

   // Some things maybe shouldn't be declarative, we're in the render-loop here with full access to the instance
   const r = 1 * Math.sin(THREE.Math.degToRad((theta += 0.1)))
   const s = Math.cos(THREE.Math.degToRad(theta * 1.75))
   group.current.rotation.set(-0.2*r, 0.1*r, 0.1*r)
   group.current.scale.set(s, s, s)
 })

 const [geo, vertices, coords] = useMemo(() => {
   const geo = new THREE.DodecahedronBufferGeometry( 2.3,0);


   

 

 

   



   const coords = new Array(2000).fill().map(i => [Math.random() * 800 - 400, Math.random() * 800 - 400, Math.random() * 800 - 400])
   return [geo,  vertices, coords]
 }, [])
 
 return (
  
   <group  scale={[7, 7, 7]} ref={group}>
 
     {coords.map(([p1, p2, p3], i) => (
       <mesh  key={i} geometry={geo} material={mat} position={[p1, p2, p3]} />
     ))}
 
   </group>
 
 )
}








export default function Octo() {
  return (
    
    <> 

 <Canvas class="octo" >
    <ambientLight color="white" />
    <pointLight color="white" intensity={1} position={[10, 10, 10]} />
   
    <spotLight
          castShadow
          intensity={6}
          angle={10}
          penumbra={1}
          position={[0, 0, 0]}
          shadow-mapSize-width={1024}
          shadow-mapSize-height={1024}
          shadow-bias={-0.0001}
        />
   

<Poliedros />
<Suspense fallback={null} >
         <Octa/>
         </Suspense>
  <OrbitControls
          autoRotate={true}
          enablePan={false}
          enableZoom={true}
          autoRotateSpeed={5}

          enableDamping
          dampingFactor={0.2}
          maxDistance = {6}
          rotateSpeed={2}

        />
          <Preload />
          <Loader/>

  </Canvas>
  
  
    </>
    
 )

}