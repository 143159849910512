import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { graphql, StaticQuery } from 'gatsby'
import Post from '../components/Post'
import PaginationLinks from '../components/PaginationLinks'
import "katex/dist/katex.min.css"

import {Im} from '../components/PageTitle/styles'
import john from '../images/ukw.png'
import '../components/styles.css'
import {P3dd} from '../components/P3d'
import Octa from '../components/Billie' 


//Octocom es el torus 


const IndexPage = () => {
  const postsPerPage = 2
  let numberOfPages
  return (
    <>
     <P3dd/>
    <div>
    <Octa/> 
   
    </div>
 
   
    <div className="indx" >
    
    <Layout pageTitle={<h1>#StandWithUkraine</h1>} >
      
      <SEO title="Homotopías" keywords={[`Math`, `Physics`, `Code`]} />

      <h1> Ukraine direct support</h1>
      <blockquote class="twitter-tweet"> 
      
    
      <a href="https://twitter.com/Ukraine/status/1497594592438497282?ref_src=twsrc%5Etfw">February 26, 2022</a> 
      </blockquote>

      <h1> UNICEF</h1>
      <blockquote class="twitter-tweet">
         <a href="https://twitter.com/UNICEF/status/1497677309171613702?ref_src=twsrc%5Etfw">February 26, 2022</a>
        </blockquote> 
        <h1> Save the children</h1>
        <blockquote class="twitter-tweet"><a href="https://twitter.com/SavetheChildren/status/1497327137841455107?ref_src=twsrc%5Etfw">February 25, 2022</a></blockquote> 


      
      
      <blockquote class="twitter-tweet"><a href="https://twitter.com/Ukraine/status/1498672323100041219?ref_src=twsrc%5Etfw">March 1, 2022</a></blockquote> 
      
      
      
      
      <iframe width="400" height="300" src="https://www.youtube.com/embed/Pdww5VGorDY?autoplay=1" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </Layout>
    </div>
    
    </>
    






    
  )

}


const indexQuery = graphql`
  query indexQuery {
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 2
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "MMM Do YYYY")
            author
            tags
            image {
              childImageSharp {
                fluid(maxWidth: 600) {
                  ...GatsbyImageSharpFluid
                }
                
              }
            }
          }
          fields {
            slug
          }
          excerpt
          
        }
      }
    }
  }

`
export default IndexPage

{/* <StaticQuery
query={indexQuery}
render={data => {
  numberOfPages = Math.ceil(
    data.allMdx.totalCount / postsPerPage
  )

  return (


    <div>
      {data.allMdx.edges.map(({ node }) => (
        
        <Post
          key={node.id}
         title={node.frontmatter.title}
          slug={node.fields.slug}
          author={node.frontmatter.author}
          body={node.excerpt}
          date={node.frontmatter.date}
          fluid={node.frontmatter.image.childImageSharp.fluid}
          tags={node.frontmatter.tags}
        />
      ))}

       &nbsp;&nbsp;
      <PaginationLinks currentPage={1} numberOfPages={numberOfPages} />
    </div>
  )
}}



/> */}