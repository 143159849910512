import React from 'react'
import {
  Badge,
  CardTitle,
  CardText,
  CardSubtitle,
  CardBody,
} from 'reactstrap'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import { slugify } from '../util/utilityFunctions'
import styled from "styled-components"
import { mediaQueries } from '../util/mediaQueries'
import {PPost} from '../components/PostExcerpt/styles'

const Div = styled.header`
 border-radius: 2em;
z-index: 7 ;
 margin-top:0em;
  color: var(--color-light);
  font-size: 1.2em;
  grid-template-areas: 'nav title darkmode search';
  grid-template-columns: auto 1fr auto auto;
  border-bottom: 0px solid var(--color-light);
  ${mediaQueries.minTablet} {
    grid-template-areas: 'title nav darkmode search';
  }
`


const Post = ({ title, author, slug, date, body, fluid, tags }) => (
 
  <Div>
  <PPost>

    <Link to={slug}>
      <Img className="card-image-top" fluid={fluid} />
    </Link>
    <CardBody>
      <CardTitle>
        <Link to={slug}><h1>{title}</h1></Link>
        
      </CardTitle>
      <CardSubtitle>
        <span className="text-info">{date}</span> por {' '}
        <span className="text-info">{author}</span>
      </CardSubtitle>
      <CardText>{}</CardText>
      
      
      <ul className="post-tags">
        {tags.map(tag => (
          <li key={tag}>
            <Link to={`/tag/${slugify(tag)}`}>
              <Badge color="primary" className="text-uppercase">
                {tag}
              </Badge>
            </Link>
          </li>
        ))}
      </ul>
      <Link
        to={slug}
        className="btn btn-outline-primary float-right text-uppercase"
      >
        Leer mas
      </Link>
    </CardBody>
    </PPost>
  </Div>
)

export default Post
